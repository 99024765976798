export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 100
  },
  {
    title: '异常内容',
    key: 'msg'
  },
  {
    title: '离线时间',
    key: 'createTime'
  },
  {
    title: '备注',
    key: 'remark'
  }
];
